import React, { useEffect, useState } from 'react'
import SecondaryLayout from '../layouts/SecondaryLayout'
import Column from '../layouts/Column'
import Title from '../components/Title/Title'
import PagePadding from '../components/PagePadding/PagePadding'
import PaginationNavigation from '../components/PaginationNavigation/PaginationNavigation'
import { Helmet } from 'react-helmet'
import { MaterialAndMaintenanceBlock } from '../components/MaterialAndMaintenance/MaterialAndMaintenance'
import { Link, graphql } from 'gatsby'

export default function Material(props) {
	const allWordpressAcfMateriaalOnderhoud =
		props.data.allWordpressAcfMateriaalOnderhoud.edges

	const allCategories = Object.keys(
		allWordpressAcfMateriaalOnderhoud[0].node.acf,
	)

	const [currentView, setCurrentView] = useState(allCategories[0])

	useEffect(() => {
		if (!props.location) return
		if (!props.location.hash) return
		setCurrentView(props.location.hash.replace('#', ''))
	}, [props.location])

	return (
		<SecondaryLayout>
			<Helmet>
				<meta name="title" content={'Materiaal en onderhoud'} />
				<meta
					name="description"
					content={
						'Bij In Balans Alkmaar vinden we het belangrijk dat uw materiaal in orde is om goed mee te draaien tijdens de lessen'
					}
				/>
			</Helmet>
			<PagePadding>
				<Column>
					<Title
						heading={1}
						text={'Materiaal & Onderhoud bij In Balans Alkmaar'}
						wave={true}
					/>

					<nav className={'PageTabSwitch'}>
						<ul className={'PageTabSwitch__list'}>
							{Object.keys(
								allWordpressAcfMateriaalOnderhoud[0].node.acf,
							).map((item, i) => (
								<li key={`link-${i}`}>
									<Link
										to={`#${item}`}
										className={`link${
											currentView === item
												? ' active'
												: ''
										}`}
										title={item}
										onClick={() => setCurrentView(item)}
									>
										{item}
									</Link>
								</li>
							))}
						</ul>
					</nav>
					<Column>
						{allWordpressAcfMateriaalOnderhoud[0].node.acf[
							currentView
						].map((categoryData, i) => {
							return (
								<MaterialAndMaintenanceBlock
									key={`block-${i}`}
									title={categoryData.title}
									text={categoryData.text}
									image={categoryData.afbeelding}
									video={categoryData.video}
								/>
							)
						})}
					</Column>
				</Column>
				<PaginationNavigation pageContext={props.pageContext} />
			</PagePadding>
		</SecondaryLayout>
	)
}

export const pageQuery = graphql`
	query {
		allWordpressAcfMateriaalOnderhoud {
			edges {
				node {
					acf {
						skeelers {
							video
							afbeelding {
								alt_text
								localFile {
									url
									childImageSharp {
										# Try editing the "maxWidth" value to generate resized images.
										fluid {
											...GatsbyImageSharpFluid_withWebp_tracedSVG
										}
									}
								}
							}
							title
							text
						}
						schaatsen {
							video
							afbeelding {
								alt_text
								localFile {
									url
									childImageSharp {
										# Try editing the "maxWidth" value to generate resized images.
										fluid {
											...GatsbyImageSharpFluid_withWebp_tracedSVG
										}
									}
								}
							}
							title
							text
						}
						mountainbikes {
							video
							afbeelding {
								alt_text
								localFile {
									url
									childImageSharp {
										# Try editing the "maxWidth" value to generate resized images.
										fluid {
											...GatsbyImageSharpFluid_withWebp_tracedSVG
										}
									}
								}
							}
							title
							text
						}
						droogtrainen {
							video
							afbeelding {
								alt_text
								localFile {
									url
									childImageSharp {
										# Try editing the "maxWidth" value to generate resized images.
										fluid {
											...GatsbyImageSharpFluid_withWebp_tracedSVG
										}
									}
								}
							}
							title
							text
						}
					}
				}
			}
		}
	}
`
