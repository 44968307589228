import React from 'react'
import './MaterialAndMaintenance.scss'
import Img from 'gatsby-image'
import ReactHtmlParser from 'react-html-parser'

export const MaterialAndMaintenanceBlock = ({ video, image, title, text }) => (
	<article className="material-and-maintenance__article">
		<figure>
			{video && video.includes('youtu') ? (
				<iframe
					src={`https://www.youtube-nocookie.com/embed/${
						video.split('v=')[1]
					}`}
					frameBorder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen
				></iframe>
			) : (
				<Img
					fluid={image.localFile.childImageSharp.fluid}
					alt={image.alt_text}
				/>
			)}
		</figure>
		<div className="material-and-maintenance__text-container">
			<h2 className="material-and-maintenance__title">{title}</h2>
			<div className="material-and-maintenance__text">
				{ReactHtmlParser(text)}
			</div>
		</div>
	</article>
)
